import React from "react"
import styled, { css } from "styled-components"
import { smMediaQuery } from "../../../theme/globalStyles"
import { DoubleLayoutFragment } from "../../../__generated__/graphql-gatsby"
import { DoubleLayoutFragment as PreviewDoubleLayoutFragment } from "../../../__generated__/graphql-wp-sdk"
import Form from "../form"
import TextBlock from "../textBlock"
import CtaTextBlock from "../textBlock/cta"
import YoutubeEmbed, { VideoWrapper } from "../youtube"
import Img from "./img"

interface IProps {
  blocks: DoubleLayoutFragment | PreviewDoubleLayoutFragment
}

export const DoubleLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${smMediaQuery(css`
    flex-direction: column;
  `)}
`

export const Column = styled.div`
  width: 50%;
  ${smMediaQuery(css`
    width: 100%;
  `)}
`

const RightColumn = styled(Column)`
  > div {
    margin-left: 0;

    ${smMediaQuery(css`
      margin-left: auto;
    `)}
  }
`

const LeftColumn = styled(Column)`
  > div {
    margin-right: 0;

    ${smMediaQuery(css`
      margin-right: auto;
    `)}
  }
`

const YoutubeWrapper = styled.div`
  height: 100%;
  display: flex;
  ${VideoWrapper} {
    width: 100%;
  }
`

const DoubleLayout: React.FC<IProps> = ({ blocks }) => {
  return (
    <DoubleLayoutWrapper>
      {blocks.doubleLayout?.map((block, i) => {
        switch (block?.__typename) {
          case "WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Img":
          case "Page_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Img":
            return (
              <Column key={`doublelayout-${i}`}>
                <Img block={block} />
              </Column>
            )
          case "WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Text":
          case "Page_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Text":
            return (
              <Column key={`doublelayout-${i}`}>
                {block.cta ? (
                  <CtaTextBlock
                    content={block.text}
                    bgColor={block.bgColor}
                    btnText={block.buttonText}
                    btnColor={block.buttonKleur}
                    btnAlignment={block.buttonAlignment}
                    btnLink={block.buttonLink?.uri}
                    maxWidth="sm"
                    position={i === 0 ? "right" : "left"}
                    alignTop={block.bovenUitlijnen}
                  />
                ) : (
                  <TextBlock
                    content={block.text}
                    bgColor={block.bgColor}
                    maxWidth="sm"
                    position={i === 0 ? "right" : "left"}
                    alignTop={block.bovenUitlijnen}
                  />
                )}
              </Column>
            )
          case "WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Formulier":
          case "Page_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Formulier":
            const WrapperColumn = i === 0 ? LeftColumn : RightColumn

            return (
              <WrapperColumn key={`doublelayout-${i}`}>
                <Form key={`doublelayout-${i}`} block={block.formulier} />
              </WrapperColumn>
            )
          case "WpPage_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Youtube":
          case "Page_PageBuilder_PageBuilder_DubbeleLayout_DoubleLayout_Youtube":
            return (
              <Column key={`doublelayout-${i}`}>
                <YoutubeWrapper>
                  <YoutubeEmbed key={i} block={block} />
                </YoutubeWrapper>
              </Column>
            )
          default:
            console.log(block)
            return null
        }
      })}
    </DoubleLayoutWrapper>
  )
}

export default DoubleLayout
