import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import {
  lgMediaQuery,
  mdMediaQuery,
  smMediaQuery,
  switchBgColor,
} from "../../../theme/globalStyles"
import { DoubleLayout_ImgFragment } from "../../../__generated__/graphql-gatsby"
import { DoubleLayout_ImgFragment as PreviewDoubleLayout_ImgFragment } from "../../../__generated__/graphql-wp-sdk"

interface IProps {
  block: DoubleLayout_ImgFragment | PreviewDoubleLayout_ImgFragment
}

interface WrapperProps {
  circle: Maybe<boolean>
  bgColor: Maybe<string>
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;

  ${({ bgColor }) => switchBgColor(String(bgColor))}

  padding: ${({ circle }) => (circle ? `68px` : 0)};

  ${({ circle }) =>
    lgMediaQuery(css`
      padding: ${circle ? `48px` : 0};
    `)}

  ${({ circle }) =>
    mdMediaQuery(css`
      padding: ${circle ? `36px` : 0};
    `)}

  ${({ circle }) =>
    smMediaQuery(css`
      padding: ${circle ? `24px` : 0};
    `)}

  .gatsby-image-wrapper {
    img {
      clip-path: ${({ circle }) => (circle ? `circle()` : `none`)};
    }
  }
`

const Img: React.FC<IProps> = ({ block }) => {
  // If preview
  if (block.img?.__typename === "MediaItem") {
    return (
      <Wrapper
        circle={block.circle}
        bgColor={block.bgColor}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src={String(block.img?.mediaItemUrl)}
          alt="preview"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Wrapper>
    )
  }

  // If not preview
  if (block.img?.__typename === "WpMediaItem") {
    if (!block.img?.localFile?.childImageSharp) {
      return (
        <Wrapper
          circle={block.circle}
          bgColor={block.bgColor}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <img
            src={String(block.img?.localFile?.publicURL)}
            alt={String(block.img?.altText)}
            style={{ width: "100%" }}
          />
        </Wrapper>
      )
    }

    return (
      <Wrapper circle={block.circle} bgColor={block.bgColor}>
        <GatsbyImage
          style={{ height: "100%" }}
          objectFit="cover"
          objectPosition="center"
          image={block.img?.localFile?.childImageSharp?.gatsbyImageData}
          alt={String(block.img?.altText)}
        />
      </Wrapper>
    )
  }

  return null
}

export default Img
