import Typography from "@material-ui/core/Typography"
import { graphql, PageProps } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import Spacer from "../../components/base/spacer"
import { useFavContext } from "../../components/context/favContext"
import { FavoriteObject } from "../../components/context/reducer"
import { Column, DoubleLayoutWrapper } from "../../components/pageBuilder/doubleLayout"
import Form, { FormState } from "../../components/pageBuilder/form"
import WpContent from "../../components/pageBuilder/textBlock/wpContent"
import { euroCurrency } from "../../hooks/useCurrency"
import { smMediaQuery } from "../../theme/globalStyles"
import { OffertePageQuery } from "../../__generated__/graphql-gatsby"

type IProps = PageProps<OffertePageQuery>

const constructMsg: (favorites: FavoriteObject[], slice?: boolean) => string = (
  favorites,
  slice
) => {
  const maxLength = 28
  let msg: string = ""
  let total: number = 0

  favorites.forEach(({ name, amount, price }) => {
    const subTotal = amount * price
    total += subTotal
    const maxLengthName = name.substring(0, maxLength) + (name.length > maxLength ? ".." : "")

    msg += `${slice ? maxLengthName : name}  -  ${amount} x ${euroCurrency(
      price
    ).format()} = ${euroCurrency(subTotal).format()}\n`
  })

  const btw = total * 0.21

  msg += `\n`
  msg += `subtotaal:  ${euroCurrency(total).format()}\n`
  msg += `btw (21%):  ${euroCurrency(btw).format()}\n`
  msg += `totaal:  ${euroCurrency(btw + total).format()}`

  return msg
}

const RightColumn = styled(Column)`
  > div {
    margin-left: 0;

    ${smMediaQuery(css`
      margin-left: auto;
    `)}
  }
`
const LeftColumn = styled(Column)`
  div {
    margin-right: 0;
  }

  ${smMediaQuery(css`
    display: none;
  `)}
`

const MsgWrapper = styled.div`
  white-space: pre-wrap;
  font-size: 115%;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.9;
  text-align: right;
  margin-right: 0;
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing(6)}px;
  padding-top: ${({ theme }) => theme.spacing(4)}px;

  max-width: 100%;
  @media (min-width: 580px) {
    max-width: 580px;
  }

  h1 {
    margin-bottom: 0;
  }
`

const OffertePage: React.FC<IProps> = ({ data: { wpForm } }) => {
  const {
    favorites: { favorites },
  } = useFavContext()
  const formData: FormState = []
  const msg = constructMsg(favorites, true)
  formData[22] = constructMsg(favorites)

  return (
    <>
      <Typography
        variant="caption"
        component="span"
        style={{
          width: "100%",
          display: "block",
          textAlign: "center",
          marginTop: 32,
          marginBottom: -16,
          zIndex: 2,
        }}
      >
        * Prijs is ex transport- en opbouwkosten, per dag
      </Typography>
      <DoubleLayoutWrapper>
        <LeftColumn>
          <WpContent>
            <MsgWrapper dangerouslySetInnerHTML={{ __html: `<h1>Producten</h1>\n${msg}` }} />
          </WpContent>
        </LeftColumn>

        <RightColumn>
          <Form
            block={wpForm}
            initState={formData}
            bigHeader
            succesMessage={
              <>
                <Spacer height={56} />
                <h1>Bedankt!</h1>
                <p>
                  <strong>We hebben je offerte aanvraag succesvol ontvangen.</strong>
                </p>
                <p>We laten zo snel mogelijk wat van ons horen.</p>
                <p>Team PUP</p>
              </>
            }
          />
        </RightColumn>
      </DoubleLayoutWrapper>
    </>
  )
}

export default OffertePage

export const query = graphql`
  query OffertePage {
    wpForm(formId: { eq: 2 }) {
      ...Form
    }
  }
`
